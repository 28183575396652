<template>
  <section id="Sustainability">
    <img src="@/assets/images/Sustainability/sustain.jpg" />
  </section>
</template>

<script>
export default {
  name: "Sustainability",
  data() {
    return {
      sections: [
        {
          title: "Waste Diversion",
          items: [
            {
              label:
                "pounds of food scraps sent to local livestock farms monthly.",
              value: "70.5K",
            },
            {
              label:
                "watts of energy created from Cigarette to Energy collection program.",
              value: "69.7K",
            },
            {
              label:
                "pounds of concessionaire food donated to local food banks monthly.",
              value: "7.3K",
            },
            { label: "pounds of cardboard recycled monthly.", value: "15.5K" },
            {
              label:
                "pounds of rubber recycled a month through Rubber Turf Pellet Recycling Program.",
              value: "3.7K",
            },
          ],
        },
        {
          title: "Local Innovation",
          items: [
            {
              label: "of old sod used as livestock bedding.",
              value: "994.26 tons",
            },
          ],
        },
        {
          title: "Biomass Machine",
          items: [
            {
              label:
                "of food scraps, paper products, and field grass clippings are processed with a 24-hour period.",
              value: "650 lbs",
            },
          ],
        },
        {
          title: "Green Purchasing",
          items: [
            {
              label:
                "of the electronics no longer needed on-site are properly e-wasted or recycled.",
              value: "100%",
            },
            {
              label: "of batteries used in stadium offices are rechargeable.",
              value: "100%",
            },
            {
              label: "of food & beverage is either organic or locally sourced.",
              value: "Over 17%",
            },
          ],
        },
        {
          title: "Energy Efficiency",
          items: [
            {
              label:
                "powered by Nevada-sourced renewable energy.<br><br>Stadium roof is composed of ETFE (Ethylene Tetrafluoroethylene).",
              value: "100%",
            },
          ],
        },
        {
          title: "Water Efficiency",
          items: [
            {
              label:
                "gallons of water saved annually.<br><br>Allegiant Stadium maintains green landscaping practices.",
              value: "535 Million",
            },
          ],
        },
        {
          title: "Lost & Found",
          items: [
            {
              label:
                "of items donated every 1 to 2 months.<br><br>The stadium donates all unclaimed items to local nonprofits.",
              value: "100 LBS",
            },
          ],
        },
        {
          title: "Green Transportation",
          items: [
            {
              label:
                "are located at three different locations at Allegiant Stadium.<br><br>16 electric charging stations that can charge up to 32 vehicles.",
              value: "35",
            },
          ],
        },
        {
          title: "Accolades & Awards",
          timeline: [
            {
              date: "May<br><span>2022</span>",
              text: "Allegiant Stadium hosted a panel regarding waste diversion and fan engagement at WasteExpo 2022.",
            },
            {
              date: "Jul<br><span>2022</span>",
              text: "Nominated by The Stadium Business Summit for theSustainability and Community Award.",
            },
            {
              date: "Mar<br><span>2023</span>",
              text: "Allegiant Stadium records a comprehensive waste diversion webinar with Green Sports Alliance.",
            },
            {
              date: "Jun<br><span>2023</span>",
              text: `Allegiant Stadium wins "Play to Zero Waste Award" for their waste diversion efforts at Green Sports Alliance.`,
            },
            {
              date: "Oct<br><span>2023</span>",
              text: "Allegiant Stadium becomes 100% powered by Nevada-sourced renewable energy.",
            },
            {
              date: "Jun<br><span>2024</span>",
              text: `Allegiant Stadium wins "Play to Zero Waste Award" and "Play to Zero Energy Champion Award" at Green Sports Alliance.`,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#Sustainability {
  position: relative;
  padding: 120px 0 50px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 992px) {
    padding: 115px 0 50px;
  }
  @media (max-width: 768px) {
    padding: 115px 0 50px;
  }
  img {
    width: 100%;
    // max-width: 1500px;
  }
}
</style>